import * as React from 'react';

export const BrokenIcon = (): JSX.Element => (
    <svg width={232} height={177} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#a)">
            <path
                d="m111.397 134.266 7.18-15.387 8.775-7.289-3.191-37.252 13.561-16.197-6.382-11.337 43.076 3.239c5.584.81 10.37 5.669 9.573 12.147l-4.787 66.407c-.797 6.479-5.583 10.528-11.167 10.528l-56.638-4.859Z"
                fill="#EEF2F5"
            />
        </g>
        <g filter="url(#b)">
            <path
                d="m85.868 44.49-43.796 5.65c-5.574.808-9.555 6.459-8.759 12.917l8.76 66.197c.796 6.458 5.573 10.495 11.944 9.687l42.998-6.458 3.982-16.953 7.166-8.88-11.147-35.52 9.555-18.568-10.352-9.687-10.351 1.615Z"
                fill="#EEF2F5"
            />
        </g>
        <mask
            id="c"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={33}
            y={42}
            width={76}
            height={98}
        >
            <path
                d="m85.868 44.49-43.796 5.65c-5.574.808-9.555 6.459-8.759 12.917l8.76 66.197c.796 6.458 5.573 10.495 11.944 9.687l42.998-6.458 3.982-16.953 7.166-8.88-11.147-35.52 9.555-18.568-10.352-9.687-10.351 1.615Z"
                fill="#EEF2F5"
            />
        </mask>
        <g mask="url(#c)">
            <path fill="#fff" d="m20.058 37.806 97.269-13.592 3.942 28.208-97.27 13.592z"/>
            <rect width={121.861} height={3.546} rx={1.773} transform="rotate(-8.632 635.16 -288.393) skewX(-.164)" fill="#7990A1"/>
            <rect width={121.861} height={3.546} rx={1.773} transform="rotate(-8.632 739.5 -295.932) skewX(-.163)" fill="#7990A1"/>
            <rect width={71.086} height={3.546} rx={1.773} transform="rotate(-8.632 687.437 -292.17) skewX(-.163)" fill="#7990A1"/>
            <rect width={71.086} height={3.546} rx={1.773} transform="rotate(-8.632 791.565 -299.693) skewX(-.163)" fill="#7990A1"/>
            <g filter="url(#d)">
                <path
                    d="M58.508 77.977a.464.464 0 0 1-.4.522l-9.982 1.334a.456.456 0 0 1-.522-.4l-.803-6.016a.472.472 0 0 1 .399-.522l9.97-1.332a.464.464 0 0 1 .522.4l.803 6.016.013-.002Z"
                    fill="#00AEEF"
                />
                <path
                    d="M57.566 71.688c-1.026 2.178-2.784 4.29-4.857 4.515-.76.09-1.662-.019-2.476-.518a3.782 3.782 0 0 1-1.593-1.968l-.224.03a.507.507 0 0 0-.421.64c.02.06.043.133.065.206.292.95 1.01 1.97 2.064 2.551.82.449 1.79.687 2.797.552 1.818-.242 3.604-1.66 4.9-4.863l-.117-.872a.474.474 0 0 0-.138-.273Z"
                    fill="#fff"
                />
            </g>
            <rect x={47.904} y={88.564} width={112} height={36} rx={4} transform="rotate(-7.607 47.904 88.564)" fill="#fff"/>
            <rect x={55} y={94.077} width={23.247} height={2.553} rx={1.277} transform="rotate(-7.607 55 94.077)" fill="#7990A1"/>
            <rect x={55.62} y={98.717} width={13.561} height={2.553} rx={1.277} transform="rotate(-7.607 55.62 98.717)" fill="#7990A1"/>
            <rect x={57.087} y={109.705} width={23.247} height={2.553} rx={1.277} transform="rotate(-7.607 57.087 109.705)" fill="#7990A1"/>
            <rect x={57.707} y={114.344} width={13.561} height={2.553} rx={1.277} transform="rotate(-7.607 57.707 114.344)" fill="#7990A1"/>
            <rect x={88} y={89.628} width={23.247} height={2.553} rx={1.277} transform="rotate(-7.607 88 89.628)" fill="#7990A1"/>
            <rect x={88.62} y={94.268} width={13.561} height={2.553} rx={1.277} transform="rotate(-7.607 88.62 94.268)" fill="#7990A1"/>
            <rect x={90.087} y={105.256} width={23.247} height={2.553} rx={1.277} transform="rotate(-7.607 90.087 105.256)" fill="#7990A1"/>
            <rect x={90.707} y={109.895} width={13.561} height={2.553} rx={1.277} transform="rotate(-7.607 90.707 109.895)" fill="#7990A1"/>
        </g>
        <mask
            id="e"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={111}
            y={46}
            width={74}
            height={94}
        >
            <path
                d="m111.397 134.266 7.18-15.387 8.775-7.289-3.191-37.252 13.561-16.197-6.382-11.337 43.076 3.239c5.584.81 10.37 5.669 9.573 12.147l-4.787 66.407c-.797 6.479-5.583 10.528-11.167 10.528l-56.638-4.859Z"
                fill="#EEF2F5"
            />
        </mask>
        <g mask="url(#e)">
            <g filter="url(#f)">
                <path
                    d="M137.141 81.173a4 4 0 0 1 4.288-3.69l26.646 1.997a4 4 0 0 1 3.69 4.288l-2.842 37.919a4 4 0 0 1-4.288 3.69l-26.646-1.997a4 4 0 0 1-3.69-4.288l2.842-37.92Z"
                    fill="#4D5C70"
                />
            </g>
            <rect x={148.794} y={87.752} width={13.727} height={2.422} rx={1.211} transform="rotate(4.287 148.794 87.752)" fill="#fff"/>
            <rect x={148.432} y={92.583} width={8.075} height={2.422} rx={1.211} transform="rotate(4.287 148.432 92.583)" fill="#fff"/>
            <rect x={147.466} y={105.466} width={13.727} height={2.422} rx={1.211} transform="rotate(4.287 147.466 105.466)" fill="#fff"/>
            <rect x={147.104} y={110.297} width={8.075} height={2.422} rx={1.211} transform="rotate(4.287 147.104 110.297)" fill="#fff"/>
            <g clipPath="url(#g)">
                <path
                    d="M144.153 89.757c-.192-.35-.422-.67-1.291-.734-.796-.06-1.448.395-1.455.965 0 .973.967 1.18 1.505 1.288.86.165 1.438.712 1.357 1.31-.096.798-.752 1.286-1.798 1.174-.796-.06-1.275-.465-1.566-.956m2.071-4.814-.503 6.71"
                    stroke="#00A2FF"
                    strokeMiterlimit={10}
                />
            </g>
            <g clipPath="url(#h)">
                <path
                    d="M142.886 106.666c-.192-.35-.423-.669-1.291-.734-.796-.06-1.449.395-1.455.965-.001.974.966 1.18 1.504 1.288.861.165 1.438.712 1.357 1.31-.096.799-.751 1.287-1.798 1.175-.796-.06-1.275-.465-1.566-.957m2.071-4.813-.503 6.71"
                    stroke="#00A2FF"
                    strokeMiterlimit={10}
                />
            </g>
            <path fill="#fff" d="m97.313 28.118 97.799 9.024-2.617 28.362-97.799-9.024z"/>
        </g>
        <g filter="url(#i)">
            <circle cx={178} cy={61} r={25} fill="#C4C4C4"/>
            <circle cx={178} cy={61} r={25} fill="url(#j)"/>
        </g>
        <g filter="url(#k)">
            <path
                d="M174.749 64.608c-.125-.799-.1-1.536.075-2.21a7.109 7.109 0 0 1 .749-1.836c.35-.575.737-1.1 1.162-1.574.449-.474.862-.924 1.236-1.349.375-.424.687-.824.937-1.198.275-.4.412-.8.412-1.2 0-.674-.212-1.148-.637-1.423-.4-.3-.887-.45-1.461-.45-.65 0-1.199.15-1.649.45-.449.3-.924.674-1.423 1.124l-3.522-3.222a11.17 11.17 0 0 1 3.259-2.548c1.274-.65 2.66-.974 4.159-.974 1.024 0 1.986.125 2.885.375.899.25 1.686.637 2.36 1.161.675.525 1.199 1.212 1.574 2.06.4.825.599 1.824.599 2.998 0 .75-.137 1.424-.412 2.023a8.304 8.304 0 0 1-1.011 1.611c-.375.5-.8.974-1.274 1.424-.45.45-.862.912-1.237 1.386a7.473 7.473 0 0 0-.936 1.536c-.225.55-.3 1.162-.225 1.836h-5.62Zm2.773 9.592c-1.099 0-1.999-.375-2.698-1.124-.699-.75-1.049-1.674-1.049-2.773 0-1.099.35-2.023 1.049-2.772.699-.75 1.599-1.124 2.698-1.124s1.998.374 2.697 1.124c.7.749 1.049 1.673 1.049 2.772 0 1.1-.349 2.023-1.049 2.773-.699.749-1.598 1.124-2.697 1.124Z"
                fill="#fff"
            />
        </g>
        <path d="m102.049 27.68 8.96 12.32m15.786-3.52 7.254-12.32M116.982 18l1.707 16.513" stroke="#ABBCC9" strokeWidth={2}/>
        <defs>
            <filter id="a" x={104.397} y={39.803} width={86.679} height={106.322} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={3} dy={3}/>
                <feGaussianBlur stdDeviation={2}/>
                <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.152941 0 0 0 0 0.188235 0 0 0 0.24 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2206_74348"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={-3} dy={-3}/>
                <feGaussianBlur stdDeviation={2}/>
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0"/>
                <feBlend in2="effect1_dropShadow_2206_74348" result="effect2_dropShadow_2206_74348"/>
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_2206_74348" result="shape"/>
            </filter>
            <filter id="b" x={26.212} y={35.875} width={88.951} height={110.169} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={3} dy={3}/>
                <feGaussianBlur stdDeviation={2}/>
                <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.152941 0 0 0 0 0.188235 0 0 0 0.24 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2206_74348"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={-3} dy={-3}/>
                <feGaussianBlur stdDeviation={2}/>
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0"/>
                <feBlend in2="effect1_dropShadow_2206_74348" result="effect2_dropShadow_2206_74348"/>
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_2206_74348" result="shape"/>
            </filter>
            <filter id="d" x={38.739} y={63.5} width={28.83} height={25.395} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={3} dy={3}/>
                <feGaussianBlur stdDeviation={3}/>
                <feColorMatrix values="0 0 0 0 0.47451 0 0 0 0 0.564706 0 0 0 0 0.631373 0 0 0 0.35 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2206_74348"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={-4} dy={-4}/>
                <feGaussianBlur stdDeviation={2}/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
                <feBlend in2="effect1_dropShadow_2206_74348" result="effect2_dropShadow_2206_74348"/>
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_2206_74348" result="shape"/>
            </filter>
            <filter id="f" x={126.288} y={73.472} width={53.488} height={63.916} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy={4}/>
                <feGaussianBlur stdDeviation={4}/>
                <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.152941 0 0 0 0 0.188235 0 0 0 0.16 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2206_74348"/>
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_2206_74348" result="shape"/>
            </filter>
            <filter id="i" x={146} y={29} width={64} height={64} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={3} dy={3}/>
                <feGaussianBlur stdDeviation={2}/>
                <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.152941 0 0 0 0 0.188235 0 0 0 0.24 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2206_74348"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx={-3} dy={-3}/>
                <feGaussianBlur stdDeviation={2}/>
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0"/>
                <feBlend in2="effect1_dropShadow_2206_74348" result="effect2_dropShadow_2206_74348"/>
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_2206_74348" result="shape"/>
            </filter>
            <filter id="k" x={162.628} y={44.198} width={30.837} height={42.002} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy={4}/>
                <feGaussianBlur stdDeviation={4}/>
                <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.152941 0 0 0 0 0.188235 0 0 0 0.16 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2206_74348"/>
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_2206_74348" result="shape"/>
            </filter>
            <clipPath id="g">
                <path fill="#fff" transform="rotate(4.287 -1103.111 1928.939)" d="M0 0h3.701v6.729H0z"/>
            </clipPath>
            <clipPath id="h">
                <path fill="#fff" transform="rotate(4.287 -1329.656 1920.453)" d="M0 0h3.701v6.729H0z"/>
            </clipPath>
            <linearGradient id="j" x1={159.704} y1={35.85} x2={190.033} y2={77.655} gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFF007"/>
                <stop offset={1} stopColor="#FFCD07"/>
            </linearGradient>
        </defs>
    </svg>
);
