import React from 'react';
import { Layout } from '../types/layouts';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Center, Heading, Text } from '@gr/ui-neo';
import { BrokenIcon } from '../icons/BrokenIcon';

export default function Custom404(): JSX.Element {
    return (
        <Center>
            <Box maxWidth="700px" boxShadow="card" background="light" padding="50px" width="100%">
                <Center flexDirection="column">
                    <Heading level={2} marginBottom="55px">
                        <FormattedMessage id="ErrorPage.Heading"/>
                    </Heading>
                    <BrokenIcon/>
                    <Text variant="paragraph-large" marginY="32px" align="center" maxWidth="500px">
                        <FormattedMessage id="ErrorPage.PageNotFoundContent"/>
                    </Text>

                    <Button
                        tag="a"
                        variant="ghost"
                        href="/"
                        size="small"
                        marginX="0px"
                        minWidth="fit-content"
                    >
                        <FormattedMessage id="ErrorPage.GoToDashboard"/>
                    </Button>
                </Center>
            </Box>
        </Center>
    );
}

Custom404.layout = Layout.NOTAUTHORIZED;
